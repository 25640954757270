import '../../assets/css/main.css';
import '../../assets/css/normalize.css'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {Link} from "react-router-dom"
import paths from '../Router/paths';
import { useFormatMessage } from '../../hooks';
import { availableLocales, flags } from '../../utils';
import { setUserLocale } from '../../state/actions/preferences';
import { Helmet } from 'react-helmet';
import Dropdown from 'react-dropdown';
import { useState } from 'react';



function Home() {
    const [message, setMessage] = useState("")
    const dispatch = useDispatch();
    const changeLocaleHandler = (local) => {
      dispatch(setUserLocale(local));
    };
    const onSelect = (value) =>{
      changeLocaleHandler(value.value)
    }
    const { locale } =  useSelector(
      (state) => ({
        locale: state.preferences.locale,
      }),
      shallowEqual
      );
      const options = () => {
        let array = []
        locales.forEach(locale => {
          array.push({ value: locale, label: locale, className: 'dropDownMenuItem' })
        });
        return array
      }
      const defaultOption = locale;

    const locales = availableLocales.filter((local) => local !== locale);

    const columnLeft = useFormatMessage('column.left')
    const columnCenter = useFormatMessage('column.center')
    const columnRight = useFormatMessage('column.right')

    const domain = window.location.hostname.replace("www.","").replace(/(^\w)|([-\s]\w)/g, match => match.toUpperCase())
  return (
    <>
      <Helmet>
        <title>{domain} | Home</title>
      </Helmet>
      <Link to="domain-for-sale" style={{ textDecoration: 'none' }}>
        <div className="domain-forsale wf-section">
          <div className="div-block">
            <div className="domain-forsale-text">{domain} {useFormatMessage('home.forsaletext')}<span className="text-span"> {useFormatMessage('home.sale')}</span> {"("}{useFormatMessage('home.enquire')} <span style={{textDecoration: "underline"}}>{useFormatMessage('home.here')}</span>{")"}</div>
          </div>
        </div>
      </Link>
      <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navigation w-nav">
    <div className="nav-wrapper" id='page-top'>
      <div className="navigation-items">
        <div className="nav-domain-wrapper">
            <Link to="#page-top" style={{ textDecoration: 'none' }}>
              <div aria-current="page" className="logo-link main w-nav-brand w--current">
                <div className="pagetitletext">{domain}</div>
              </div>
            </Link>
            <Link to="#page-top" style={{ textDecoration: 'none' }}>
              <div aria-current="page" className="logo-link w-nav-brand w--current">
                <div className="text-block">{useFormatMessage('home.anydomain')}</div>
              </div>
            </Link>
          </div>
        <div class="navigation-wrap">
          <nav role="navigation" class="navigation-items w-nav-menu">
            <a href="#" class="navigation-item mobile w-nav-link">{useFormatMessage('nav.news')}</a>
            <a href="#" class="navigation-item mobile w-nav-link">{useFormatMessage('nav.people')}</a>
            <a href="#" class="navigation-item mobile w-nav-link">{useFormatMessage('nav.about')}</a>
            <Dropdown controlClassName='dropdown-item'  menuClassName='dropDownMenu' options={options()} onChange={(value) => onSelect(value)} value={defaultOption} placeholder="Select an option" />
            <a href="#" class="navigation-item last w-nav-link">{useFormatMessage('nav.contact')}</a>
          </nav>
          {/* <div class="menu-button w-nav-button"><img src="images/menu-icon_1menu-icon.png" width="22" alt="" class="menu-icon"/></div> */}
        </div>
      </div>
      <div class="navigation-items-right">
        <div class="navigation-wrap">
          <nav role="navigation" class="navigation-items w-nav-menu">
            <a href="#" class="navigation-item w-nav-link">{useFormatMessage('nav.news')}</a>
            <a href="#page-top" class="navigation-item w-nav-link">{useFormatMessage('nav.people')}</a>
            <a href="#" class="navigation-item w-nav-link">{useFormatMessage('nav.about')}</a>
            <a href="#" class="navigation-item last w-nav-link">{useFormatMessage('nav.contact')}</a>
          </nav>
        </div>
        <div class="div-block-2">
          <div>What are you looking for?</div>
          <div class="div-block-3">
            <input
              className='class="text-block-2'
              type="text"
              value={message}
              placeholder="Search here..."
              onChange={e => setMessage(e.target.value)}
            />
            
          </div>
        </div>
      </div>
    </div>
    <div class="podm-wrapper">
      <div class="podm-container">
        <div class="part-of">part of</div>
        <h1 class="dubai-magazine">Dubai-Magazine.com</h1>
      </div>
    </div>
  </div>
      {/* <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navigation w-nav">
        <div className="navigation-items">
          <div className="nav-domain-wrapper">
            <Link to="/" style={{ textDecoration: 'none' }}>
              <div aria-current="page" className="logo-link main w-nav-brand w--current">
                <div className="pagetitletext">{window.location.hostname}</div>
              </div>
            </Link>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <div aria-current="page" className="logo-link w-nav-brand w--current">
                <div className="text-block">{useFormatMessage('home.anydomain')}</div>
              </div>
            </Link>
          </div>
          <div className="navigation-wrap">
            <nav role="navigation" className="navigation-items w-nav-menu">
              <a href="#" className="navigation-item mobile w-nav-link">{useFormatMessage('nav.news')}</a>
              <a href="#" className="navigation-item mobile w-nav-link">{useFormatMessage('nav.people')}</a>
              <a href="#" className="navigation-item mobile w-nav-link">{useFormatMessage('nav.about')}</a>
              <Dropdown controlClassName='navigation-item'  menuClassName='dropDownMenu' options={options()} onChange={(value) => onSelect(value)} value={defaultOption} placeholder="Select an option" />
              <a href="#" className="navigation-item last w-nav-link">{useFormatMessage('nav.contact')}</a>
            </nav>
            <div className="menu-button w-nav-button"><img src="images/menu-icon_1menu-icon.png" width="22" alt="" className="menu-icon"/></div>
          </div>
        </div>
        <div className="navigation-items-right">
          <Link to="/" style={{ textDecoration: 'none' }}>
            <div className="navigation-wrap">
              <nav role="navigation" className="navigation-items w-nav-menu">
                <div className="navigation-item first w-nav-link">{useFormatMessage('nav.news')}</div>
              </nav>
            </div>
          </Link>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <div className="navigation-wrap">
              <nav role="navigation" className="navigation-items w-nav-menu">
                <div  className="navigation-item w-nav-link">{useFormatMessage('nav.people')}</div>
              </nav>
            </div>
          </Link>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <div className="navigation-wrap">
              <nav role="navigation" className="navigation-items w-nav-menu">
                <div  className="navigation-item w-nav-link">{useFormatMessage('nav.about')}</div>
              </nav>
            </div>
          </Link>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <div className="navigation-wrap">
              <nav role="navigation" className="navigation-items w-nav-menu">
                <div href="#" className="navigation-item w-nav-link">{useFormatMessage('nav.contact')}</div>
              </nav>
            </div>
          </Link>
          <div className="navigation-wrap">
            <div className="menu-button w-nav-button"><img src="images/menu-icon_1menu-icon.png" width="22" alt="" className="menu-icon"/></div>
          </div>
        </div>
      </div> */}
      <div className="section">
        <div className="section-wrapper">
          <div className="column">
            <div className="w-richtext" dangerouslySetInnerHTML={{ __html: columnLeft }} />
          </div>
          <div className="column center">
            <div className="w-richtext" dangerouslySetInnerHTML={{ __html: columnCenter }} />
          </div>
          <div className="column">
            <div className="w-richtext" dangerouslySetInnerHTML={{ __html: columnRight }} />
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="footer-wrapper">
          <div>
            <a href="https://webflow.com/" target="_blank" className="webflow-link w-inline-block"><img src="images/webflow-w-small2x_1webflow-w-small2x.png" width="15" alt="" className="webflow-logo-tiny"/>
              <div className="paragraph-tiny">ANY-Web Limited</div>
            </a>
          </div>
          <div className="footer-links">
            <a href="https://www.facebook.com/webflow/" target="_blank" className="footer-item">Facebook</a>
            <a href="https://twitter.com/webflow" target="_blank" className="footer-item">Twitter</a>
            <a href="https://www.instagram.com/webflowapp/" target="_blank" className="footer-item">Instagram</a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
