import '../../assets/css/main.css';
import {Link} from "react-router-dom"
import paths from '../Router/paths';

function News() {
  return (
    <div className="PageHome">
      <div className="Magazine">
      <div className='Header'>
        <div className='PageTitle'>
          <h1>Dubai-Magazine.com News</h1>
        </div>
        <div className='NavBar'>
          {}
          <Link to={"/news"}>
            <button className='NavItem'><h2>News</h2></button>
          </Link>
          <button className='NavItem' onClick={() => alert("hi")}><h2>People</h2></button>
          <button className='NavItem' onClick={() => alert("hi")}><h2>About</h2></button>
          <button className='NavItem' onClick={() => alert("hi")}><h2>Contact</h2></button>
        </div>
      </div>
      <div className='ColumnSection'>
        <div className="column">
          <h2>Column 1</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rutrum congue leo eget malesuada. Vivamus suscipit tortor eget felis porttitor volutpat.</p>
        </div>
        <div className="column">
          <h2>Column 2</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rutrum congue leo eget malesuada. Vivamus suscipit tortor eget felis porttitor volutpat.</p>
        </div>
        <div className="column">
          <h2>Column 3</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rutrum congue leo eget malesuada. Vivamus suscipit tortor eget felis porttitor volutpat.</p>
        </div>
      </div>
      </div>
    </div>
  );
}

export default News;
