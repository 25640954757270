import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './assets/css/main.css';
import reportWebVitals from './reportWebVitals';
import Router from './pages/Router';
import LanguageWrapper from './components/LanguageWrapper';
import { configureStores } from './state/store';
import './assets/css/main.css'

const { store, persistor } = configureStores({});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <LanguageWrapper>
      <Router/>
    </LanguageWrapper>
  </Provider>
  

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
