import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


import { preferencesReducer } from './preferences';



export default combineReducers({
  preferences: persistReducer(
    { key: 'preferences', storage },
    preferencesReducer
  )
});
