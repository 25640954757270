export default {
  ROOT: '/',
  HOME: '/home',
  NEWS: '/news',
  DOMAIN: '/domain-for-sale',
  PROJECTS: '/projects',
  PROJECT: '/project/:id',
  CONTACT: '/contact',
  SERVICES: '/service-detail',
  TEAMMEMBER: '/team-member/:id'
};
