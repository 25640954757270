import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Domain from '../domainSale';
import Home from '../home';
import News from '../news';


import paths from './paths';
// import PrivateRoute from './PrivateRoute';










const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={paths.ROOT} element={<Home/>} />
        <Route exact path={paths.NEWS} element={<News/>} />
        <Route exact path={paths.DOMAIN} element={<Domain/>} />

      </Routes>
    </BrowserRouter>
  );
};

export default RouterComponent;
