/* eslint-disable */
import { createIntl, createIntlCache } from 'react-intl';

import english from '../languages/en';
import deutsch from '../languages/de';



export const messages = {
  en: english,
  de: deutsch,
};

export const availableLocales = Object.keys(messages);

export const browserLocale = navigator.language.split(/[-_]/)[0];



